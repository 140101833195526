<template>
  <v-container fluid>
    <Bar
      :chart-options="chartOptions"
      :chart-data="statisticChartDataUniqueClients"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
    <div class="mt-2">
      <p class="mb-0">
        Кількість унікальних клієнтів:
        <span v-if="!isObjectEmpty(statisticChartDataUniqueClients)" class="font-weight-bold">
          {{ this.statisticChartDataUniqueClients.totalCount }}
        </span>
      </p>
    </div>
  </v-container>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js'
import { mapState, mapActions } from 'vuex'
import { isObjectEmpty } from '@/helpers/isObjectEmpty'

ChartJS.register(Title, Tooltip, Legend, CategoryScale, LinearScale, BarElement)

export default {
  name: 'FinancialStatementUniqueClients',

  components: { Bar },

  props: {
    chartId: {
      type: String,
      default: 'pie-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
    },
  }),

  computed: {
    ...mapState('payments', ['statisticChartPayload', 'statisticChartDataUniqueClients']),
  },

  watch: {
    statisticChartPayload: {
      handler(newValue) {
        this.initialize(newValue)
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    isObjectEmpty,
    ...mapActions('payments', ['loadStatisticUniqueClients']),

    initialize() {
      this.loadStatisticUniqueClients(this.statisticChartPayload)
    },
  },
}
</script>

<style scoped></style>
